export const useModalsStore = defineStore('modals', () => {
  const modals = ref(new Map<string, Record<any, any>>())

  const closeModal = (modal: string) => {
    modals.value.delete(modal)
  }

  const openModal = (modal: string, props?: Record<any, any>) => {
    modals.value.set(modal, props ? props : {})
  }
  return { modals, closeModal, openModal }
})
